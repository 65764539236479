import { Heading, Flex, Container, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export async function getStaticProps() {
  return {
    props: {},
  }
}

const NotFoundPage = () => {
  const router = useRouter()
  useEffect(() => {
    router.replace('/')
  })
  return (
    <Container variant="full">
      <Container variant="boxed" py={40} h="full">
        <Flex direction="column" w="full" alignItems="center">
          <Heading fontSize="6rem" lineHeight="5rem">
            404
          </Heading>
          <Heading size="sm" textAlign="center" my={6}>
            Cette page est introuvable, comme des tomates en hiver
          </Heading>
          <Text>Vous allez être rediriger vers la page d&apos;accueil</Text>
        </Flex>
      </Container>
    </Container>
  )
}
export default NotFoundPage
